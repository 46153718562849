@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: none;
  font-smooth: none;
 
}
:root{
  --normal-text: 100;
  --subheading-text:600;
  --mainheading-text:700;
  
}
svg{
  color: white;
  background-color: white;
}
#root{
  overflow: hidden;
  background:#F9F9F9;
}
.box {
  width: 200px;
  height: 100px;
  padding: 10px;
  border: 1px solid black;
  box-sizing: border-box;
  background-color: yellow;
}

.defaultpadding {
  padding: 10% 10%;

}
.top {
  padding: 10% 0px;

}
.active{
  font-weight: 600;
}
.headingpadding {
  padding: 10% 12%;
}
.image__container {
  max-width: 100%;
  max-height: 100%;
}

.profile__container {
  max-width: 400px;
  min-width: 400px;
  max-height: 400px;
  min-height: 400px;
}

.icon {
  color: "#6D6D6D";
}

.footer {
  padding: 5% 10%;
}
.menu_item {
  min-width: 80px;
}


button,
link {
  cursor: pointer;
}
.grid-2{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  place-items: center;
  
}
.grid-3{
  display: grid;
  grid-template-columns: repeat(3,1fr);
}

